import { getAuth } from "firebase/auth";
import { getFirestore, collection, doc, setDoc, deleteDoc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { emptyRule, Rule } from "./Rules/rulesTypes";
import RuleTableRow from "./Rules/RuleTableRow";

const Admin = () => {
  const [rules, setRules] = useState<Rule[]>([]);
  
  const user = getAuth().currentUser;

  // Get rules from Firestore
  useEffect(() => {
    const db = getFirestore();

    const unsub = onSnapshot(
      collection(db, "rules"),
      (querySnapshot) => {
        const tempRules: Rule[] = [];
        querySnapshot.forEach((doc) => {
          tempRules.push({ id: doc.id, ...doc.data() } as Rule);
        });
        setRules(tempRules);
      }
    );

    return unsub;
  }, []);

  const updateRule = (rule: Rule) => {
    const db = getFirestore();
    const ruleRef = doc(db, "rules", rule.id);

    let d: any = {...rule};
    delete d.id;
    setDoc(ruleRef, d);
  }

  const deleteRule = (rule: Rule) => {
    const db = getFirestore();
    const ruleRef = doc(db, "rules", rule.id);
    deleteDoc(ruleRef);
  }

  return (
    <div className="p-3">
      <h1 className="text-3xl mb-3">
        👋🏽 Welcome, {user?.displayName?.split(" ")[0]}!
      </h1>
      <p className="text-lg mb-3">Here are the active rules:</p>
      <table>
        <thead className="text-purple-700 uppercase bg-purple-50">
          <tr>
            <th className="px-6 py-3">Description</th>
            <th className="px-6 py-3">Pattern</th>
            <th className="px-6 py-3">URL</th>
            <th className="px-6 py-3">Actions</th>
          </tr>
        </thead>
        <tbody>
          {rules.map((rule) => (
            <RuleTableRow 
              key={rule.id} 
              rule={rule} 
              onEdit={(r) => updateRule(r)}
              onDelete={(r) => deleteRule(r)}
            />
          ))}
        </tbody>
      </table>
      <button
        className="rounded-md bg-purple-500 text-md text-white px-3 py-1 mt-3"
        onClick={() => {
          const newRule = emptyRule();
          setRules([...rules, newRule]);
          updateRule(newRule);
        }}
      >
        💡 Add New Rule
      </button>
    </div>
  );
}

export default Admin;