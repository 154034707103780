import { v4 as uuidv4 } from 'uuid';

interface Rule {
  id: string,
  description: string,
  pattern: string,
  redirect: string
}

const emptyRule = () => ({
  id: uuidv4(),
  description: '',
  pattern: '',
  redirect: ''
})

export type { Rule };
export { emptyRule };