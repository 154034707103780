import { useState } from 'react';
import { Rule } from './rulesTypes';

interface RuleTableRowProps {
  rule: Rule;
  onEdit: (rule: Rule) => void;
  onDelete: (rule: Rule) => void;
}

const RuleTableRow = ({ rule: staticRule, onEdit, onDelete }: RuleTableRowProps) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [edit, setEdit] = useState(false);
  const [rule, setRule] = useState<Rule>({...staticRule});


  const displayOptions = () => {
    if (edit) return displayEdit();
    if (confirmDelete) return displayConfirmDelete();
    return displayDefault();
  }

  const displayDefault = () => (
    <>
      <button
        className="rounded-md bg-teal-400 text-white px-3 py-1 mr-2"
        aria-label="Edit rule"
        title="Edit rule"
        onClick={() => setEdit(true)}
      >
        ✏️
      </button>
      <button
        className="rounded-md bg-red-400 text-white px-3 py-1"
        aria-label="Delete rule"
        title="Delete rule"
        onClick={() => setConfirmDelete(true)}
      >
        🗑️
      </button>
    </>
  );

  const displayConfirmDelete = () => (
    <>
      <p className="text-rose-600 font-bold">Are you sure?</p>
      <button
        className="rounded-md bg-teal-400 text-white px-3 py-1 mr-2"
        aria-label="confirm delete"
        title="confirm delete"
        onClick={() => onDelete(rule)}
      >
        ✓
      </button>
      <button
        className="rounded-md bg-red-400 text-white px-3 py-1"
        aria-label="cancel"
        title="cancel"
        onClick={() => setConfirmDelete(false)}
      >
        ✕
      </button>
    </>
  );


  const displayEdit = () => (
    <>
      <button
        className="rounded-md bg-teal-400 text-white px-3 py-1 mr-2"
        aria-label="save"
        title="save"
        onClick={() => { onEdit(rule); setEdit(false); } }
      >
        💾
      </button>
    </>
  );

  const generateEditableField = (field: string) => {
    const key = field as keyof typeof rule;

    if (edit) {
      return (
        <input
          value={rule[key]}
          onChange={(e) => setRule({...rule, [key]: e.target.value})}
          className="border border-gray-300 rounded-md px-2 py-1"
        />
      )
    }

    if (field === "redirect") {
      // style this as a link
      return (
        <a
          href={rule.redirect}
          className="text-blue-500 hover:text-blue-600 hover:underline"
        >
          {rule.redirect}
        </a>
      );
    }

    return rule[key];
  }

  return (
    <tr key={rule.id} className="text-left border-b">
      <td className="px-6 py-3 font-bold">
        {generateEditableField("description")}
      </td>
      <td className="font-mono px-6 py-3">
        {generateEditableField("pattern")}
      </td>
      <td className="px-6 py-3">
        {generateEditableField("redirect")}
      </td>
      <td className="px-6 py-3">{displayOptions()}</td>
    </tr>
  );
};

export default RuleTableRow;