import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import Loader from "../Loader";

interface getURLResponseType {
  redirect: string;
  description: string;
}

const Redirect = () => {
  const [loading, setLoading] = useState(true);
  const [urls, setUrls] = useState<getURLResponseType[]>([]);

  useEffect(() => {
    const functions = getFunctions();
    const getURLs = httpsCallable(functions, "getURLs");
    
    getURLs()
      .then(res => res.data)
      .then(data => setUrls(data as getURLResponseType[]))
      .then(() => setLoading(false));
  }, [])
  
  if (loading)
    return <Loader />;

  if (urls.length === 1) {
    window.location.href = urls[0].redirect;
    return null;
  }

  return (
    <div className="p-3">
      <h1 className="text-3xl">Redirect to...</h1>
      <ul className="list-disc ml-5 mt-3 text-lg">
        {
        urls.map(url => (
          <li key={url.redirect}>
            <a 
              href={url.redirect}
              className="text-blue-500 hover:text-blue-600 hover:underline"
            >{url.description}</a>
          </li>
        ))
        }
      </ul>
    </div>
  );
}

export default Redirect;