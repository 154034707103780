import { 
  getAuth, 
  GoogleAuthProvider, 
  onAuthStateChanged, 
  signInWithRedirect 
} from "firebase/auth";
import {
  getDoc,
  getFirestore,
  doc
} from "firebase/firestore";
import { useEffect, useState } from "react";
import Loader from "../Loader";
import Admin from "../Admin";
import Redirect from "../Redirect";

function App() {
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    const loadComponent = async (user: any) => {
      // Is the user an admin?
      const db = getFirestore();
      const docRef = doc(db, "users", user.uid);
      const snap = await getDoc(docRef);
      if (snap.exists()) 
        setAdmin(snap.data().admin);
      
      // Done loading
      setLoading(false);
    };

    onAuthStateChanged(auth, (user) => {
      if (!user)
        signInWithRedirect(auth, provider);
      else
        loadComponent(user);
    });
  }, []);

  if (loading)
    return <Loader />;
  else if (admin)
    return <Admin />;
  else  
    return <Redirect />;
}

export default App;
