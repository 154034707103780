import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBNU1W-rtmFkZ97IQWVMaEkU8wkRiaGnHc",
  authDomain: "call-redirect-5237c.firebaseapp.com",
  projectId: "call-redirect-5237c",
  storageBucket: "call-redirect-5237c.appspot.com",
  messagingSenderId: "764715640928",
  appId: "1:764715640928:web:5ea6fb943a3d509dc9bb05",
};

getFunctions(initializeApp(firebaseConfig));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);